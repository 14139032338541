<template>
  <div class="loyalty-upgrade">
    <header class="page-header">
      <PageTitle v-if="loyaltyUpgrade" :item="loyaltyUpgrade"/>
      <div class="buttons">
        <button v-if="!isLoading && checkPermissions(['loyalty_upgrade.edit'])" class="btn btn-save" @click="saveLoyaltyUpgrade()">
          <span>{{ loyaltyUpgrade && loyaltyUpgrade.id ? 'Loyalty upgrade wijzigen' : 'Loyalty upgrade toevoegen' }}</span>
        </button>
      </div>
    </header>

    <div class="main-content">
      <div class="pane table-pane">
        <formulate-form v-if="loyaltyUpgrade && !isLoading" v-model="loyaltyUpgrade" @submit="saveLoyaltyUpgrade">
          <formulate-input :options="Array.from(Array(26).keys())" label="Scores" multiple name="scores" type="select" validation="required"></formulate-input>
          <formulate-input :options="types" label="Type" name="type" type="select" validation="required"></formulate-input>
          <formulate-input #default="{ index }" groupRepeatable-class="translations-group formulate-input-group-repeatable" name="translations" type="group">
            <h3>Vertaling: {{ activeLocalesBack[getLangCode(loyaltyUpgrade, index)] }}</h3>
            <button v-if="getLangCode(loyaltyUpgrade, index) !== 'nl'" class="btn btn-remove-translation" type="button" @click="removeTrans(loyaltyUpgrade, index)">Remove</button>

            <formulate-input name="lang_code" type="hidden"></formulate-input>
            <formulate-input label="Titel" name="title" type="text" validation="required"></formulate-input>
            <formulate-input label="Body" name="body" type="tiptap"/>
          </formulate-input>

          <div v-if="Object.keys(activeLocalesBack).length > loyaltyUpgrade.translations.length">
            <h4>{{ $t('Vertaling toevoegen') }}</h4>

            <div class="language-buttons">
              <button v-if="!hasTrans(loyaltyUpgrade,'fr')" class="btn" type="button" @click="addTrans(loyaltyUpgrade, 'fr')">{{ activeLocalesBack['fr'] }}</button>
              <button v-if="!hasTrans(loyaltyUpgrade,'en')" class="btn" type="button" @click="addTrans(loyaltyUpgrade, 'en')">{{ activeLocalesBack['en'] }}</button>
              <button v-if="!hasTrans(loyaltyUpgrade,'sv')" class="btn" type="button" @click="addTrans(loyaltyUpgrade, 'sv')">{{ activeLocalesBack['sv'] }}</button>
            </div>
          </div>

          <formulate-input :label="isLoading ? $t('Even geduld...') : $t('Opslaan')" input-class="btn" type="submit"/>
        </formulate-form>
      </div>
    </div>
  </div>
</template>

<script>
import { ApiService } from '@/services/admin/api.service'
import { notification } from '@/notifications'
import PageTitle from '@/components/admin/PageTitle'

export default {
  components: { PageTitle },
  data () {
    return {
      isLoading: true,
      loyaltyUpgradeId: this.$route.params.id,
      loyaltyUpgrade: null,
      types: {
        1: 'Omzet',
        2: 'Potentieel & groei',
        3: 'Duurzaamheid & anciënniteit',
        4: 'Betaalgedrag',
        5: 'Kwaliteit & training personeel',
        // 6: 'Klachten NVT',
        // 7: 'DNA match NVT',
        8: 'Opvolging leads',
        // 9: 'Loyaliteit NVT',
        10: 'Marketing & zichtbaarheid',
        11: 'Bestelwijze',
        12: 'Gevolgde producten'
      },
      upgradePointTypes: {
        volume_revenue_score: 1,
        volume_growth_score: 2,
        volume_seniority_score: 3,
        quality_payment_behavior_score: 4,
        quality_training_staff_score: 5,
        quality_complaints_returns_after_sales: 6,
        quality_dna_match: 7,
        sales_marketing_followup_leads_score: 8,
        sales_marketing_loyalty: 9,
        sales_marketing_visibility_score: 10,
        quality_payment_method_score: 11,
        quality_followed_products_score: 12
      }
    }
  },
  async mounted () {
    if (this.loyaltyUpgradeId) {
      await this.fetchLoyaltyUpgrade(this.loyaltyUpgradeId)
    }

    if (!this.loyaltyUpgrade) {
      this.loyaltyUpgrade = {
        translations: [
          {
            lang_code: this.$i18n.locale
          }
        ]
      }
    }

    this.isLoading = false
  },
  methods: {
    fetchLoyaltyUpgrade: async function (id) {
      this.loyaltyUpgrade = (await ApiService.fetchLoyaltyUpgrade(id)).data
    },
    async saveLoyaltyUpgrade () {
      this.isLoading = true

      const res = this.loyaltyUpgrade.id ? await ApiService.saveLoyaltyUpgrade(this.loyaltyUpgrade) : await ApiService.createLoyaltyUpgrade(this.loyaltyUpgrade)

      if (res.status === 200) {
        notification(this.loyaltyUpgrade.id ? this.$t('Loyalty upgrade succesvol aangepast!') : this.$t('Loyalty upgrade succesvol aangemaakt!'))
        await this.$router.push({ name: 'admin.loyaltyupgrades' })
      }

      this.isLoading = false
    }
  },
  computed: {}
}
</script>
